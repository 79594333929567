import { Auth0Client } from '@auth0/auth0-spa-js'
import { scopes } from '../config/index'
import { getEnvVariable } from '../utils/getEnvVariable'

const getAudience = (): string => getEnvVariable('REACT_APP_AUTH0_AUDIENCE', '')
const getDomain = (): string => getEnvVariable('REACT_APP_AUTH0_DOMAIN', 'unset')
const getClientId = (): string => getEnvVariable('REACT_APP_AUTH0_CLIENT_ID', 'unset')
const getLoginUrl = (): string => getEnvVariable('REACT_APP_LOGIN_URL', 'unset')

const maxInitTime = 1000

const getAuth0Client = (organization: string) => {
  const auth0Client: Auth0Client = new Auth0Client({
    domain: getDomain(),
    clientId: getClientId(),
    cacheLocation: 'localstorage',
    authorizationParams: {
      // eslint-disable-next-line camelcase
      redirect_uri: window.location.origin,
      organization,
      scope: scopes,
      audience: getAudience(),
    }
  })

  return auth0Client
}

let auth0Client: Auth0Client | null = null
let initTime: number | null = null

export const initAuth0 = (organization?: string) => {
  if (!organization || auth0Client) return

  auth0Client = getAuth0Client(organization || '')
  initTime = Date.now()
}

export const logout = async () => {
  const currentTime = Date.now()
  if (initTime && currentTime - initTime < maxInitTime) return

  try {
    await auth0Client?.logout({ logoutParams: { federated: false }, clientId: getClientId() })
  } catch (err) {
    console.error('Error during logout:', err)
  }
}

export const getUser = async () => auth0Client?.getUser()

export const getAccessToken = async () => auth0Client?.getTokenSilently()

export const getIsAuthenticated = async () => {
  const isAuthenticated = await auth0Client?.isAuthenticated() || false
  return isAuthenticated
}

export const login = (email: string) => {
  if (typeof window !== 'undefined' && email) {
    const url = `${getLoginUrl()}` +
    `?email=${email}` +
    `&callback=${window.location.origin}` +
    `&a=${getAudience()}` +
    `&c=${getClientId()}`

    window.location.href = url
  }
}